@mixin text-base() {
  @include regular();
  @include text-size(16, 19);

  p, .title-3 {
    margin-bottom: .9em;
  }

  p + h2 {
    padding-top: .6em;
  }

  h2 {
    @extend %title-2;
    margin-bottom: .7em;
  }

  h3 {
    @extend %title-3;
    margin-bottom: .7em;
  }

  h4 {
    @extend %title-4;
    margin-bottom: .7em;
  }

  h5 {
    @extend %title-4;
    margin-bottom: .7em;
  }

  p, ul li, ol li, blockquote, pre {
    @include text-size(16, 19);
  }

  a {
    color: $dark-yellow;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    @include bold();
  }

  em {
    @include italic();
  }

  ol, ul {
    margin-left: 24px;
    margin-bottom: 24px;

    li {
      margin-bottom: 10px;

      ol, ul {
        padding-top: 10px;
      }
    }
  }
  ol {
    li {
      list-style: decimal;
    }
  }

  ul {
    li {
      list-style: disc;
    }
  }

  sup {
    vertical-align: super;
  }

  blockquote {
    padding-left: 2 * $side-margin - 2px;
    border-left: 2px solid $grey-intense;
    margin-bottom: 24px;
    @include italic();

    :last-child {
      margin-bottom: 0;
    }
  }

  pre {
    @include monospace();
    margin-bottom: 24px;
    padding: 17px (2 * $side-margin) 22px (2 * $side-margin);
    background: $grey-light;
    white-space: normal;

    :last-child {
      margin-bottom: 0;
    }
  }

  code {
    @include monospace();
    background: #FAFAFA;
    border: 1px solid #EFEFF1;
    color: $dark-blue;
    mix-blend-mode: darken;
    border-radius: 2px;
  }

  .footnotes {
    padding: 14px 0 0 0;
    @include text-size(14, 17);
    color: $text-secondary;
  }

  iframe {
    display: block;
    margin-bottom: 22px;
    width: 100%;
    height: 56vw; // get the appropriate ratio using vw
  }

  table {
    width: 100%;

    thead td, th {
      padding: 8px 12px;
      @include text-size(14, 17);
      @include medium();
      border-bottom: 1px solid $black-off;
    }

    tbody td, td {
      padding: 8px 12px;
      @include text-size(14, 17);
    }
  }
}

@mixin text-base-tablet() {
  @include text-size(17, 21);

  p, ul li, ol li, blockquote, pre {
    @include text-size(17, 21);
  }

  .footnotes {
    padding: 22px 0 0 0;
    @include text-size(15, 19);
  }

  iframe {
    height: 320px;
    margin-bottom: 24px;
  }

  table {
    thead td, th {
      @include text-size(15, 19);
    }

    tbody td, td {
      @include text-size(15, 19);
    }
  }
}

@mixin text-base-desktop() {
  @include text-size(18, 23);

  p, ul li, ol li, blockquote, pre {
    @include text-size(18, 23);
  }

  .footnotes {
    padding: 30px 0 0 0;
    @include text-size(16, 21);
  }

  table {
    thead td, th {
      @include text-size(16, 21);
    }

    tbody td, td {
      @include text-size(16, 21);
    }
  }
}
