.bubble {
  position: absolute;
  width: 220px;
  margin-top: 25px;
  left: 50%;
  margin-left: -110px;
  text-align: center;
  padding: 9px 8px;
  border: 1px solid $grey-intense;
  background-color: $white;
  @include text-size(16, 21);

  @include arrow-top(16px, $white, $grey-intense);
}
