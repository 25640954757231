.highlighted {
  position: relative;
  white-space: nowrap;

  &:before {
    content: ' ';
    display: block;
    @include absolute(0, -3px, 0, -3px);
    z-index: 1;
    mix-blend-mode: darken;
    background-color: $yellow;
  }

  &.-small:before {
    transform: rotate(-1deg);
  }

  &.-medium:before {
    transform: rotate(-0.6deg);
  }

  &.-large:before {
    transform: rotate(-0.3deg);
  }

  &.-small.-reversed:before {
    transform: rotate(1deg);
  }

  &.-medium.-reversed:before {
    transform: rotate(0.6deg);
  }

  &.-large.-reversed:before {
    transform: rotate(0.3deg);
  }

  span {
    position: relative;
    z-index: 2;
  }
}
