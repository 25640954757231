*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  text-decoration: none;
  box-sizing: border-box;
  list-style: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: baseline;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article, aside, details, dialog, figcaption, figure,  footer, header, hgroup, main, menu, nav, section, svg {
  display: block;
}

body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  background-color: $page-bg;
  @include regular();
  color: $text-default;
  text-align: left;

  &.-noScroll {
    overflow: hidden;
  }
}

a {
  color: $text-default;
}

a, area, button, input:not([type="range"]), label, select, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

button {
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6, strong {
  font-size: 1rem;
  font-weight: normal;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
