/* font family helpers */

@mixin regular() {
  font-family: 'GT-Zirkon-regular', sans-serif;
}

@mixin italic() {
  font-family: 'GT-Zirkon-italic', sans-serif;
  font-style: normal;
}

@mixin medium() {
  font-family: 'GT-Zirkon-medium', sans-serif;
}

@mixin bold() {
  font-family: 'GT-Zirkon-bold', sans-serif;
}

@mixin monospace() {
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
}

%title-1 {
  @include bold();
  @include text-size(30, 33);
  letter-spacing: -0.04em;

  @media #{$mq-tabletPortrait} {
    @include text-size(34, 37);
  }

  @media #{$mq-desktop} {
    @include text-size(38, 42);
  }
}

%title-2 {
  @include bold();
  @include text-size(24, 29);
  letter-spacing: -0.04em;

  @media #{$mq-tabletPortrait} {
    @include text-size(26, 31);
  }

  @media #{$mq-desktop} {
    @include text-size(28, 34);
  }
}

%title-3 {
  @include medium();
  @include text-size(20, 24);

  @media #{$mq-tabletPortrait} {
    @include text-size(22, 26);
  }

  @media #{$mq-desktop} {
    @include text-size(22, 26);
  }
}

%title-4 {
  @include medium();
  @include text-size(18, 22);

  @media #{$mq-tabletPortrait} {
    @include text-size(22, 24);
  }

  @media #{$mq-desktop} {
    @include text-size(20, 24);
  }
}

%title-5 {
  @include medium();
  @include text-size(16, 20);

  @media #{$mq-tabletPortrait} {
    @include text-size(17, 22);
  }

  @media #{$mq-desktop} {
    @include text-size(18, 23);
  }
}

@font-face {
  font-family: 'GT-Zirkon-regular';
  src: url('#{$font-base-url}/fonts/GT-Zirkon-Regular.woff2') format('woff2'),
       url('#{$font-base-url}/fonts/GT-Zirkon-Regular.woff') format('woff'),
       url('#{$font-base-url}/fonts/GT-Zirkon-Regular.ttf') format('ttf'),
       url('#{$font-base-url}/fonts/GT-Zirkon-Regular.eot') format('eot');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'GT-Zirkon-italic';
  src: url('#{$font-base-url}/fonts/GT-Zirkon-Regular-Italic.woff2') format('woff2'),
       url('#{$font-base-url}/fonts/GT-Zirkon-Regular-Italic.woff') format('woff'),
       url('#{$font-base-url}/fonts/GT-Zirkon-Regular-Italic.ttf') format('ttf'),
       url('#{$font-base-url}/fonts/GT-Zirkon-Regular-Italic.eot') format('eot');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'GT-Zirkon-medium';
  src: url('#{$font-base-url}/fonts/GT-Zirkon-Medium.woff2') format('woff2'),
       url('#{$font-base-url}/fonts/GT-Zirkon-Medium.woff') format('woff'),
       url('#{$font-base-url}/fonts/GT-Zirkon-Medium.ttf') format('ttf'),
       url('#{$font-base-url}/fonts/GT-Zirkon-Medium.eot') format('eot');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'GT-Zirkon-bold';
  src: url('#{$font-base-url}/fonts/GT-Zirkon-Bold.woff2') format('woff2'),
       url('#{$font-base-url}/fonts/GT-Zirkon-Bold.woff') format('woff'),
       url('#{$font-base-url}/fonts/GT-Zirkon-Bold.ttf') format('ttf'),
       url('#{$font-base-url}/fonts/GT-Zirkon-Bold.eot') format('eot');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
