.title {
  &-1 {
    @extend %title-1;
  }
  &-2 {
    @extend %title-2;
  }
  &-3 {
    @extend %title-3;
  }
  &-4 {
    @extend %title-4;
  }
  &-blog1 {
    @include bold();
    @include text-size(24, 29);
    letter-spacing: -0.04em;
  }
}

@media #{$mq-tabletPortrait} {
  .title {
    &-blog1 {
      @include text-size(34, 37);
    }
  }
}

@media #{$mq-desktop} {
  .title {
    &-blog1 {
      @include text-size(38, 42);
    }
  }
}
