.referral {
  padding: 80px 0;

  &-content {

  }

  &-title {
    text-align: center;
    max-width: 470px;
    margin: 22px auto;
  }

  &-text {
    text-align: center;
    max-width: 470px;
    margin: 20px auto;
  }

  &-form {
    text-align: center;
    .input-block {
      margin-bottom: 30px;
    }
  }
}

@media #{$mq-tabletPortrait} {
  .referral {
    padding: 90px 0;
  }
}
