.grid {
  display: none;
  pointer-events: none;
  @include fixed(0, $side-margin, 0, $side-margin, $grid-zIndex);
  background: rgba(255, 0, 0, .08);

  &.-visible {
    display: block;
  }

  &-col {
    display: none;
    @include absolute(0, auto, 0, auto);
    width: 50%;

    span {
      display: block;
      @include absolute(0, ($side-margin/2), 0, ($side-margin/2));
      background: rgba(255, 0, 0, .1);
    }

    &:first-child, &:last-child {
      display: block;
    }

    &:first-child {
      span {
        left: $side-margin;
      }
    }

    &:last-child {
      right: 0;

      span {
        right: $side-margin;
      }
    }
  }
}

@media #{$mq-tabletPortrait} {
  .grid {
    width: $content-tabletWidth;
    left: 50%;
    right: auto;
    margin-left: -($content-tabletWidth / 2);
    background: transparent;

    &-col {
      display: block;
      width: $grid-tablet-col;

      span {
        left: 0;
        right: 0;
      }

      &:first-child, &:last-child {
        right: auto;
        span {
          left: 0;
          right: 0;
        }
      }

      @for $i from 1 through 12 {
        &.col#{$i} {
          left: ($grid-tablet-col + $side-margin) * ($i - 1);
        }
      }
    }
  }
}

@media #{$mq-desktop} {
  .grid {
    width: $content-desktopWidth;
    margin-left: -($content-desktopWidth / 2);

    &-col {
      width: $grid-desktop-col;

      @for $i from 1 through 12 {
        &.col#{$i} {
          left: ($grid-desktop-col + $side-margin) * ($i - 1);
        }
      }
    }
  }
}
