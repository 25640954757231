@mixin button-base() {
  display: inline-block;
  padding: 0 30px;
  appearance: none;
  border-radius: 4px;
  @include text-size(18, 40);
  @include medium();
  background-color: $button-bg;
  color: $button-text;
  border: none;
}
