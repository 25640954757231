.input {
  &-text {
    @include input-base();
    width: 100%;

    &.-phone {
      width: 186px;
    }
  }

  &-label {
    display: block;
    margin-bottom: 2px;
    color: $text-secondary;
    @include text-size(16, 19);
  }

  &-error {
    display: block;
    padding: 4px 23px 4px 0px;
    color: $dark-red;
    background: $light-red;
    @include text-size(13, 17);
  }

  &-success {
    display: block;
    padding: 4px 4px 4px 0px;
    color: $dark-green;
    background: $light-green;
    @include text-size(13, 17);
  }

  &-block {
    position: relative;
    margin-bottom: 12px;

    &.-inline {
      display: inline-block;
      vertical-align: top;
      margin-right: 10px;
      margin-bottom: 0;
    }

    &.-phoneInput .input-error, &.-phoneInput .input-success {
      margin-bottom: 5px;
      margin-left: 66px;
      max-width: 186px;
    }

    /* intl tel input overrides */
    .intl-tel-input.allow-dropdown .selected-flag {
      height: 39px;
      background-color: $input-bg;
      border-bottom: 1px solid $input-border;
    }

    .intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
      background-color: $input-bg;
    }

    .intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=tel], .intl-tel-input.allow-dropdown input[type=text] {
      @include input-base();
      margin-left: 66px;
    }
  }

  &-checkbox {
    &Label {
      display: block;
      cursor: pointer;
      padding: 8px 0 10px 0;
      @include text-size(16, 19);
    }
  }

  &-toggle {
    float: left;
    position: relative;
    margin: 9px 13px;
    width: 38px;
    height: 20px;
    background-color: $grey-medium;
    border: 1px solid $text-default;
    border-radius: 20px;
    cursor: pointer;
    transition-duration: 150ms;
    transition-timing-function: ease-out;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background-color: $text-default;
      top: 3px;
      left: 4px;
      transition-duration: 150ms;
      transition-timing-function: ease-out;
    }

    &.-isOn {
      background-color: $yellow;

      &:after {
        left: 21px;
      }
    }

    &.-hasError {
      background-color: $white;
      border-color: $dark-red;

      &:after {
        background-color: $dark-red;
      }
    }
  }

  &-globalError {
    padding: 14px 0 0 0;
    text-align: center;
    @include text-size(16, 21);
    color: $dark-red;
  }

  &-globalSuccess {
    padding: 14px 0 0 0;
    text-align: center;
    @include text-size(16, 21);
    color: $dark-green;
  }
}
