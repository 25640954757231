/* Media Queries */
$mq-tabletPortrait: "only screen and (min-width: 768px)";
$mq-desktop: "only screen and (min-width: 1152px)";

/* Dimensions */
$side-margin: 12px;
$header-mobileHeight: 40px;
$header-height: 60px;
$content-tabletWidth: 732px;
$content-desktopWidth: 948px;

/* Grid */
$grid-tablet-col: 50px;
$grid-desktop-col: 68px;

/* Design system colors */
$black-off: #212125;
$grey-dark: #5B5B66;
$grey-intense: #A8A8B2;
$grey-medium: #DEDEE2;
$grey-light: #EEEEF0;
$white-off: #F9F9FA;
$white: #fff;
$dark-yellow: #CC8F00;
$yellow: #FED400;
$light-yellow: #FFF8D6;
$dark-red: #A30000;
$light-red: #FFD6D6;
$dark-green: #007011;
$light-green: #CBFFD4;
$dark-blue: #002D70;
$light-blue: #E0EDFF;

/* semantic colors */
$page-bg: $white;
$text-default: $black-off;
$text-disabled: $grey-intense;
$text-secondary: $grey-intense;

$button-bg: $black-off;
$button-text: $white;
$button-lightBg: $white;
$button-lightBorder: $black-off;
$button-lightText: $black-off;

$input-bg: $white-off;
$input-border: $black-off;
$input-border-error: $dark-red;

$header-bg: $white;
$header-borderColor: $black-off;
$header-hover: $grey-medium;
$header-on: $yellow;

$footer-bg: $grey-medium;
$footer-company-text: $grey-dark;
$footer-company-border: $grey-intense;

$mobile-media-mask: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, $white 70px, $white 100%);

/* positioning */
$header-zIndex: 50;
$register-zIndex: 100;
$grid-zIndex: 1000;
