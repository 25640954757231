.header {
  &:before {
    display: block;
    content: " ";
    @include fixed(0, 0, 0, 0, $header-zIndex - 1);
    background-color: rgba(255, 255, 255, 0);
    backdrop-filter: blur(0);
    transition: 400ms ease-out;
    pointer-events: none;
  }

  &-wrap {
    @include fixed(0, 0, auto, 0, $header-zIndex);
    bottom: auto;
    height: $header-mobileHeight;
    border-bottom: 1px dotted $header-borderColor;
    background: $header-bg;
  }

  &-logo {
    display: block;
    height: 40px;
    width: 112px;
    background-image: url(/images/logo-cashbee.svg);
    background-size: 86px auto;
    text-indent: -9000px;
  }

  &-menu {
    @include fixed(40px, 0, 0, auto, $header-zIndex);
    display: block;
    width: 64%;
    min-width: 196px;
    padding: 18px $side-margin;
    background: $header-bg;
    border-left: 1px solid $header-borderColor;
    text-align: right;
    transform: translateX(100%);
    transition: 300ms ease-out;
    pointer-events: none;
  }

  &-menuItem {
    float: right;
    clear: both;
    @include text-size(18, 23);
    margin-bottom: 12px;
    border-bottom: 2px solid transparent;

    &:hover {
      border-bottom-color: $header-hover;
    }

    &.-isOn {
      border-bottom-color: $header-on;
    }
  }

  &-register {
    display: none;
  }

  &-iosButton {
    position: absolute;
    bottom: 16px;
    right: $side-margin;
  }

  &-locales {
    position: absolute;
    bottom: 72px;
    right: $side-margin;
  }

  &-locale {
    display: inline-block;
    width: 32px;
    height: 24px;
    margin-left: 8px;
    border: 1px solid transparent;
    border-radius: 2px;
    text-align: center;
    background-size: 24px 16px;
    text-indent: -9000px;

    &.-isOn {
      border-color: $text-disabled;

      &:hover {
        border-color: $text-disabled;
      }
    }

    &:hover {
      border-color: $header-on;
    }
  }

  &-burger {
    display:block;
    position: absolute;
    right: $side-margin;
    top: 14px;
    width: 18px;
    height: 12px;
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      left: 0;
      height: 2px;
      width: 18px;
      background: $header-borderColor;
      border-radius: 2px;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      opacity: 1;
      transform-origin: left center;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2) {
        top: 5px;
      }

      &:nth-child(3) {
        top: 10px;
      }
    }
  }

  &.-opened {
    .header-burger {
      span {
        width: 20px;

        &:nth-child(1) {
          transform: rotate(45deg);
          top: -1px;
        }

        &:nth-child(2) {
          width: 0;
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 13px;
        }
      }
    }

    .header-menu {
      transform: translateX(0);
      pointer-events: all;
    }

    &:before {
      background-color: rgba(255, 255, 255, .8);
      backdrop-filter: blur(1px);
      pointer-events: all;
    }
  }
}

@media #{$mq-tabletPortrait} {
  .header {
    &:before {
      display: none;
    }

    &-wrap {
      height: $header-height;
    }

    &-contentWrap {
      position: relative;
      width: $content-tabletWidth;
      margin: 0 auto;
    }

    &-logo {
      position: absolute;
      z-index: 2;
      left: -8px;
      top: 6px;
      width: 150px;
      height: 50px;
      background-size: 120px auto;
    }

    &-menu {
      position: relative;
      z-index: 1;
      top: 0;
      border-left: none;
      width: auto;
      padding: 10px 10px 0 0;
      transform: translateX(0);
      pointer-events: all;
    }

    &-menuItem {
      display: inline-block;
      float: none;
      clear: none;
      @include text-size(17, 21);
      margin-bottom: 0;
      margin-right: 26px;
    }

    &-iosButton {
      display: none;
    }

    &-register {
      display: inline-block;
    }

    &-locales {
      position: relative;
      display: inline-block;
      width: 21px;
      height: 14px;
      bottom: auto;
      right: auto;
      margin-right: 24px;
    }

    &-locale {
      position: absolute;
      left: 0;
      top: 1px;
      z-index: 2;
      margin-left: 0;
      width: 21px;
      height: 14px;
      border: none;
      background-size: 21px 14px;
      border-radius: 0;
      cursor: pointer;
      opacity: 0;

      &:hover {
        opacity: 1;
      }

      &.-isOn {
        z-index: 1;
        opacity: 1;
      }
    }

    &-burger {
      display: none;
    }
  }
}

@media #{$mq-desktop} {
  .header {
    &-contentWrap {
      width: $content-desktopWidth;
    }
  }
}
