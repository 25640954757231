.input-block {
  .intl-tel-input {
    .iti-flag {
      background-image: url(/images/intl-tel-input-flags@1x.png);
    }

    .selected-flag {
      padding-left: 12px;

      .iti-arrow {
        right: 10px;
      }
    }

    &.allow-dropdown .selected-flag {
      width: 56px;
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2) {
  .input-block {
    .intl-tel-input {
      .iti-flag {
        background-image: url(/images/intl-tel-input-flags@2x.png);
      }
    }
  }
}
