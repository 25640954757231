.icon {
  &:before {
    content: ' ';
    display: inline-block;
    height: 24px;
    width: 24px;
    background-position: center;
    background-size: 100%;
  }

  &.-twitter:before {
    background-image: url(/images/icons/twitter.svg);
  }

  &.-linkedin:before {
    background-image: url(/images/icons/linkedin.svg);
  }

  &.-email:before {
    background-image: url(/images/icons/email.svg);
  }

  &.-emailShare:before {
    background-image: url(/images/icons/email-share.svg);
  }

  &.-jobs:before {
    background-image: url(/images/icons/work.svg);
  }

  &.-close:before {
    background-image: url(/images/icons/close.svg);
  }

}
