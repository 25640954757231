
/**!
	FONT SIZE AND LINE-HEIGHT VAR
*/

@function rem-value($sizeValue) {
	@return (0.0625 * $sizeValue) + rem;
}

@mixin font-size($sizeValue){
	font-size: $sizeValue + px; //fallback for old browsers
	font-size: rem-value($sizeValue);
}

// line-height mixin (base 20)
@mixin line-height($heightValue){
	line-height: $heightValue + px; //fallback for old browsers
	line-height: rem-value($heightValue);
}

// @include text-size(36, 40);
@mixin text-size($sizeValue, $heightValue) {
	@include font-size($sizeValue);
	@include line-height($heightValue);
}

@mixin absolute($args...) {
	position: absolute;
	@include positions($args...);
}

@mixin fixed($args...) {
	position: fixed;
	@include positions($args...);
}

@mixin relative($args...) {
	position: relative;
	@include positions($args...);
}

@mixin reset-relative() {
	position: relative;
	@include positions(auto);
}

@mixin positions($args...) {
	$top: nth($args, 1);
	$right: "";
	$bottom: "";
	$left: "";

	@if length($args) < 4 {
    $right: $top;
		$bottom: $top;
		$left: $top;
  }
	@else {
		$right: nth($args, 2);
		$bottom: nth($args, 3);
		$left: nth($args, 4);
	}

	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;

	@if length($args) == 5 {
		z-index: nth($args, 5);
	}
}


@mixin tablet-col($property_name, $nb, $modifier: 0px) {
	#{$property_name}: col-size($grid-tablet-col, $nb, $modifier);
}

@mixin desktop-col($property_name, $nb, $modifier: 0px) {
	#{$property_name}: col-size($grid-desktop-col, $nb, $modifier);
}

@function col-size($base-size, $nb, $modifier: 0px) {
	@return (($base-size + $side-margin) * $nb) + $modifier;
}

@mixin arrow-top($size, $background, $border) {
	&:after, &:before {
		position: absolute;
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
	}

	&:after {
		border-color: rgba(0, 0, 0, 0);
		border-bottom-color: $background;
		border-width: $size;
		margin-left: -$size;
	}

	&:before {
		border-color: rgba(0, 0, 0, 0);
		border-bottom-color: $border;
		border-width: $size + 1;
		margin-left: -($size + 1);
	}
}

@mixin triangle($size, $background) {
	&:before {
		position: absolute;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		border-color: rgba(0, 0, 0, 0);
		border-bottom-color: $background;
		border-width: $size;
	}
}

@mixin cross-icon($size, $color) {
	position: relative;
	display: inline-block;
	width: $size * 1.414;
	height: $size * 1.414;
	text-indent: -9000px;
	transform: rotate(45deg);
	transform-origin: center;

	&:before, &:after {
		content: " ";
		display: block;
		position: absolute;
		border-radius: 2px;
		background: $color;
	}

	&:before {
		width: round($size * 1.414);
		height: 2px;
		top: 50%;
		margin-top: -1px;
		left: 0;
	}

	&:after {
		height: round($size * 1.414);
		width: 2px;
		top: 0;
		left: 50%;
		margin-left: -1px;
	}
}

@mixin icon-to($angle, $size, $thickness, $color) {
	width: $size;
	height: $size;
	border-top: $thickness solid $color;
	border-left: $thickness solid $color;
	transform: rotate($angle);
}

@mixin icon-next($size, $thickness, $color) {
	@include icon-to(135deg, $size, $thickness, $color);
}

@mixin icon-back($size, $thickness, $color) {
	@include icon-to(-45deg, $size, $thickness, $color);
}
