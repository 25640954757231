.button {
  @include button-base();

  &.-light {
    @include text-size(18, 36);
    padding: 0 24px;
    background-color: $button-lightBg;
    border: 2px solid $button-lightBorder;
    color: $button-lightText;
  }

  &.-large {
    padding-left: 59px;
    padding-right: 59px;
  }

  &-success {
    display: inline-block;
    padding: 0 30px;
    text-align: center;
    border-radius: 4px;
    @include text-size(18, 40);
    @include medium();
    background-color: $dark-green;
    color: $button-text;

    &.-large {
      padding-left: 59px;
      padding-right: 59px;
    }

    &.-cover {
      position: absolute;
      padding: 0;
      top: 0;
      left: -1px;
      right: -1px;
    }
  }
}
