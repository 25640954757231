.error404 {
  padding-top: 36px;
  text-align: center;

  &-img {
    display: block;
    padding-bottom: 70%;
    margin-bottom: 26px;
    background-color: $light-yellow;
  }

  &-title {
    margin-bottom: 20px;
  }

  &-button {
    padding-bottom: 68px;
  }
}

@media #{$mq-tabletPortrait} {
  .error404 {
    padding-top: 71px;
    @include tablet-col('padding-left', 2);
    @include tablet-col('padding-right', 2);
  }
}

@media #{$mq-desktop} {
  .error404 {
    @include desktop-col('padding-left', 3);
    @include desktop-col('padding-right', 3);
  }
}
