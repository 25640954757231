.main {
  padding-top: $header-mobileHeight;
  padding-bottom: 252px;

  &-content {
    margin: 0 auto;
    padding: 0 ($side-margin * 2);
  }
}

@media #{$mq-tabletPortrait} {
  .main {
    padding-top: $header-height;
    padding-bottom: 165px;

    &-content {
      width: $content-tabletWidth;
      padding: 0;
    }
  }
}

@media #{$mq-desktop} {
  .main {
    &-content {
      width: $content-desktopWidth;
    }
  }
}
