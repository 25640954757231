@mixin input-base() {
  display: inline-block;
  padding: 10px 12px 9px 12px;
  border-radius: 0;
  appearance: none;
  border: none;
  border-bottom: 1px solid $input-border;
  @include medium();
  color: $text-default;
  background-color: $input-bg;
  @include text-size(16, 20);
  outline: none;

  &.-hasError {
    border-bottom-color: $input-border-error;
  }
}
