.text {
  h1 {
    @extend %title-1;
    margin-bottom: 24px;
  }

  @include text-base();

  &.-bold {
    @include medium();
  }

  &-small {
    @include text-size(14, 17);

    &.-bold {
      @include medium();
    }
  }

  &-big {
    @include text-size(20, 24);

    &.-bold {
      @include bold();
    }
  }
}

@media #{$mq-tabletPortrait} {
  .text {
    @include text-base-tablet();

    &-small {
      @include text-size(15, 19);
    }

    &-big {
      @include text-size(26, 31);
    }
  }
}

@media #{$mq-desktop} {
  .text {
    @include text-base-desktop();

    &-small {
      @include text-size(16, 21);
    }

    &-big {
      @include text-size(28, 34);
    }
  }
}
