html {
  height: 100%;
}
body {
  min-height: 100%;
  position: relative;
}

.footer {
  padding-top: 20px;
  padding-bottom: 18px;
  background-color: $footer-bg;
  @include absolute(auto, 0, 0, 0);

  &-company {
    padding-bottom: 19px;
    margin-bottom: 15px;
    color: $footer-company-text;
    border-bottom: 1px solid $footer-company-border;
  }

  &-logo {
    display: block;
    width: 120px;
    height: 28px;
    margin-bottom: 16px;
    background: url(/images/logo-cashbee.svg);
    background-size: 100%;
  }

  &-address {
    margin-bottom: 6px;
  }

  &-navs {
    position: relative;
  }

  &-navItem {
    display: block;
    margin-bottom: 9px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-contacts {
    position: absolute;
    top: 0;
    right: 0;
  }

  &-contactItem {
    display: block;
    width: 22px;
    position: relative;
    margin-bottom: 9px;
    text-indent: -9000px;

    &:last-child {
      margin-bottom: 0;
    }

    // icon
    &:before {
      position: absolute;
      left: 0;
      top: -4px;
    }
  }
}

@media #{$mq-tabletPortrait} {
  .footer {
    padding-top: 24px;
    padding-bottom: 30px;

    .main-content {
      display: flex;
    }

    &-company {
      vertical-align: top;
      @include tablet-col('width', 6);
      padding-bottom: 0;
      margin: 0;
      border-bottom: none;
    }

    &-logo {
      margin-bottom: 34px;
    }

    &-navs {
      vertical-align: top;
      margin: 0;
      @include tablet-col('width', 6, -$side-margin);
    }

    &-contactItem {
      width: auto;
      padding-left: 35px;
      padding-right: 5px;
      text-indent: 0;
    }
  }
}

@media #{$mq-desktop} {
  .footer {
    &-company {
      @include desktop-col('width', 6);
    }

    &-logo {
      margin-bottom: 34px;
    }

    &-navs {
      @include desktop-col('width', 6, -$side-margin);
    }
  }
}
